import { createTheme } from '@material-ui/core/styles';

const darkTheme = createTheme({
  palette: {
    primary: {
      main: '#f9c206',
      light: 'rgba(249, 194, 6, 0.5)',
      dark: '#E0B112',
    },
    secondary: {
      main: '#6c757d',
      light: '#323B45',
    },
    background: {
      default: '#1E2833',
    },
    text: {
      primary: '#ffffff',
      secondary: '#000',
    },
  },
  typography: {
    fontFamily: 'Mulish, sans-serif !important',
    h1: {
      fontSize: 25,
      fontWeight: 700,
    },
    h2: {
      fontSize: 18,
      paddingRight: 5,
      fontWeight: 700,
      '@media (min-width:600px)': {
        fontSize: 21,
      },
    },
    h3: {
      fontSize: 24,
      fontWeight: 700,
      '@media (min-width:600px)': {
        fontSize: 36,
      },
    },
    h4: {
      fontSize: 18,
      fontWeight: 700,
      '@media (min-width:600px)': {
        fontSize: 24,
      },
    },
    h5: {
      fontSize: 20,
      fontWeight: 700,
    },
    h6: {
      fontSize: 16,
      '@media (min-width:600px)': {
        fontSize: 18,
      },
    },
    body2: {
      fontSize: 14,
      '@media (min-width:600px)': {
        fontSize: 18,
      },
    },
  },
  overrides: {
    MuiTypography: {
      h3: {
        fontSize: 20,
        fontWeight: 700,
      },
    },
    MuiButton: {
      root: {
        textTransform: 'inherit',
        padding: 10,
        borderRadius: 6,
        fontWeight: 700,
        fontSize: 16,
      },
      text: {
        padding: 10,
      },
    },
    MuiFilledInput: {
      root: {
        borderRadius: 6,
        borderTopLeftRadius: 6,
        borderTopRightRadius: 6,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: '#d3d5dc',
        backgroundColor: 'transparent',
        '&:before': {
          content: 'none',
        },
        '@media (hover: none)': {
          '&:hover': {
            backgroundColor: 'transparent !important',
          },
        },
        '&:after': {
          content: 'none',
        },
        '&$focused': {
          backgroundColor: 'transparent',
          borderColor: '#93979B',
        },
        '&$disabled': {
          backgroundColor: '#F7F8FA',
        },
        '&:hover': {
          backgroundColor: 'transparent',
        },
        '&$error': {
          color: '#f44336',
          borderColor: '#f44336',
        },
      },
      multiline: {
        paddingTop: 32,
        paddingLeft: 16,
        paddingBottom: 10,
      },
      input: {
        fontWeight: 600,
        paddingTop: 32,
        paddingBottom: 10,
        paddingLeft: 16,
        '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button,': {
          '-webkit-appearance': 'none',
        },
      },
      inputMarginDense: {
        paddingTop: 32,
        paddingBottom: 10,
        paddingLeft: 16,
      },
      inputAdornedEnd: {
        paddingRight: 5,
        textOverflow: 'ellipsis',
      },
    },
    MuiInputLabel: {
      filled: {
        '&$shrink': {
          transform: 'translate(16px, 10px) scale(0.88) !important',
        },
      },
    },
    MuiSelect: {
      select: {
        boxSizing: 'border-box',
      },
      filled: {
        '&:focus': {
          backgroundColor: 'transparent',
          borderColor: '#93979B',
        },
      },
      iconFilled: {
        top: 'calc(50% - 3.75px)',
        right: 16,
      },
    },
    MuiPopover: {
      paper: {
        marginTop: 6,
      },
    },
    MuiPaper: {
      root: { backgroundColor: '#2C333C' },
    },
    MuiFormControl: {
      marginDense: {
        marginTop: 0,
      },
    },
    MuiFormLabel: {
      root: {
        color: '#59606D',
        '&$focused': {
          color: 'black',
        },
        '&$disabled': {
          color: '#59606D',
        },
      },
    },
    MuiFormControlLabel: {
      label: {
        fontSize: 14,
        fontWeight: 600,
        color: '#000',
      },
    },
    MuiCard: {
      root: {
        borderRadius: 6,
        boxShadow: '0 3px 5px 0 rgba(0, 0, 0, 0.05)',
      },
    },
    MuiCardContent: {
      root: {
        padding: 20,
        '&:last-child': {
          paddingBottom: 20,
        },
        '@media (min-width:960px)': {
          padding: 24,
          '&:last-child': {
            paddingBottom: 24,
          },
        },
      },
    },
    MuiList: {
      padding: {
        paddingLeft: 4,
        paddingRight: 4,
      },
    },
    MuiListItem: {
      root: {
        borderRadius: 4,
        '&$selected': {
          borderRadius: 4,
        },
      },
    },
    MuiInputBase: {
      root: {
        color: 'black',
      },
      input: {
        '&::placeholder': {
          opacity: 1,
          color: '#ABAFB5',
        },
        '&:disabled': {
          color: 'black',
        },
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: '#323B45',
      },
    },
  },
});
export default darkTheme;
