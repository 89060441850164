import React, {FC} from 'react';
import Link from 'next/link'
import {Grid, makeStyles, Theme, Typography} from "@material-ui/core";

interface PageLinkProps {
  href: string;
  text: string
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    margin: theme.spacing(2, 0, 0, 2.5),
    width: 258,
    [theme.breakpoints.up('md')]: {
      margin: 0,
      width: '29%',
    },
    [theme.breakpoints.up('lg')]: {
      width: '37%',
    },
  },
  item: {
    backgroundColor: theme.palette.background.paper,
    maxWidth: 258,
    minHeight: 36,
    display: 'flex',
    justifyContent: 'center',
    padding: 10,
    borderRadius: 6,
    background: 'linear-gradient(90deg, #ECDA14 0%, #C83502 100%)',
    cursor: 'pointer'
  },
  text: {
    color: theme.palette.common.white,
    fontSize: 16,
    fontWeight: 800,
  },
}));

const RebatesLink: FC<PageLinkProps> = (props) => {
  const {href, text} = props;
  const classes = useStyles();

  return (
    <Grid container className={classes.container}>
      <Grid className={classes.item} item sm={12}>
        <Link href={href}>
          <Typography className={classes.text}>{text}</Typography>
        </Link>
      </Grid>
    </Grid>
  );
};

export default RebatesLink;
