import { Theme, useMediaQuery } from '@material-ui/core';

const options = {
  // noSsr: true,
};

const useMediaQueries = (query: string | ((theme: Theme) => string)): boolean =>
  useMediaQuery(query, options);

export default useMediaQueries;
