import { useState } from 'react';

const useImageError = (): [boolean, () => void] => {
  const [imageError, setImageError] = useState<boolean>(false);

  const handleLogoError = () => {
    setImageError(true);
  };

  return [imageError, handleLogoError];
};

export default useImageError;
