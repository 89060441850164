import { createTheme } from '@material-ui/core/styles';

const energy5Theme = createTheme({
  palette: {
    customColors: {
      main: '#979797',
      light: '#93BEDF',
      lightGray: '#F8F8F8',
      gray: '#E6E6E6',
      contrastText: '#707070',
    },
    background: {
      default: '#ffffff',
      paper: '#373737',
    },
    primary: {
      main: '#F9C106',
      dark: '#373737',
    },
    secondary: {
      main: '#333',
    },
    text: {
      primary: '#59606D',
      secondary: '#d6d6d6',
    },
    common: {
      black: '#000',
    },
  },
  spacing: 5,
  typography: {
    fontFamily: 'Mulish, sans-serif !important',
    h1: {
      fontSize: 28, // dont change
      fontWeight: 700,
      '@media (min-width:414px)': {
        fontSize: 32,
      },
      '@media (min-width:600px)': {
        fontSize: 38,
      },
      '@media (min-width:960px)': {
        fontSize: 48,
      },
    },
    h2: {
      fontSize: 32, // dont change
      fontWeight: 700,
      '@media (min-width:600px)': {
        fontSize: 42,
      },
    },
    h3: {
      fontSize: 24, // dont change
      fontWeight: 700,
      '@media (min-width:600px)': {
        fontSize: 36,
      },
    },
    h4: {
      fontWeight: 700,
      fontSize: 24, // dont change
    },
    h5: {
      fontSize: 20,
      fontWeight: 700,
      '@media (min-width:600px)': {
        fontSize: 24, // dont change
      },
    },
    h6: {
      fontSize: 16, // dont change
      fontWeight: 400,
      lineHeight: 1.5,
      '@media (min-width:600px)': {
        fontSize: 18,
      },
    },
    body1: {
      fontSize: 16, // dont change
      '@media (min-width:600px)': {
        fontSize: 18,
      },
    },
    body2: {
      fontSize: 14, // dont change
      '@media (min-width:600px)': {
        fontSize: 16,
      },
    },
  },
  overrides: {
    MuiPaper: {
      root: {
        backgroundColor: '#ffffff',
      },
    },
    MuiButton: {
      root: {
        textTransform: 'inherit',
        padding: 10,
        borderRadius: 6,
        fontWeight: 700,
        fontSize: 16,
      },
      text: {
        padding: 10,
      },
    },
    MuiFormControl: {
      marginDense: {
        marginTop: 0,
      },
    },
    MuiCssBaseline: {
      '@global': {
        '*, *::before, *::after': {
          boxSizing: 'border-box',
        },
      },
    },
    MuiContainer: {
      fixed: {
        '@media(min-width: 1920px)': {
          maxWidth: 1280,
        },
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: '#eaeaea',
      },
    },
  },
});
export default energy5Theme;
