import { useRouter } from 'next/router';
import { Plan } from 'services/quotes/types';
import { RETAIL_RESIDENTIAL_CUSTOMER_INFORMATION_ROUTE } from 'config/retail/residential/routes';
import { RETAIL_COMMERCIAL_CUSTOMER_INFORMATION_ROUTE } from 'config/retail/commercial/routes';
import retailStores from 'stores/retail';
import { triggerRetailSelectBestPlanEvent, triggerRetailSelectPlanEvent } from 'config/seo/events';

const useHandleSelectPlan = () => {
  const { push, asPath } = useRouter();
  const { plansStore } = retailStores;
  const { startOrderSession, isResidential, isPlanWithTOS, setTOSPlan } = plansStore;

  return async (plan: Plan, isAccepted = false, esignature = '') => {
    if (isPlanWithTOS(plan) && !isAccepted) {
      setTOSPlan(plan);
      return;
    }

    await startOrderSession(plan, asPath, esignature);
    if (plan.isTheBest) {
      triggerRetailSelectBestPlanEvent(plan);
    } else {
      triggerRetailSelectPlanEvent(plan);
    }
    await push(
      isResidential
        ? RETAIL_RESIDENTIAL_CUSTOMER_INFORMATION_ROUTE
        : RETAIL_COMMERCIAL_CUSTOMER_INFORMATION_ROUTE
    );
  };
};

export default useHandleSelectPlan;
