import React, { FC, ReactNode } from "react";
import MainLayout, { MainLayoutInterface } from "containers/common/layouts/MainLayout";
import energy5Theme from "config/themes/energy5Theme";
import Metadata, { MetadataProps } from "evcharging/containers/layout/Metadata";

interface PageProps extends MetadataProps, MainLayoutInterface {
  children: ReactNode;
  withoutMetadata?: boolean;
}

const Page: FC<PageProps> = ({
  children,
  title,
  description,
  locales,
  theme = energy5Theme,
  hideFooterOnly,
  mt,
  showTransparentBg,
  hideNav,
  withoutMetadata = false,
}) => {
  return (
    <>
      {withoutMetadata || <Metadata title={title} description={description} locales={locales} />}
      <MainLayout
        theme={theme}
        hideFooterOnly={hideFooterOnly}
        mt={mt}
        showTransparentBg={showTransparentBg}
        hideNav={hideNav}
      >
        {children}
      </MainLayout>
    </>
  );
};

export default Page;
