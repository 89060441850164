import React, {FC, useEffect, useState} from 'react';
import AppBar from '@material-ui/core/AppBar';
import {Theme} from '@material-ui/core';
import DesktopMenu from 'containers/common/sections/layout/Nav/DesktopMenu';
import MobileMenu from 'containers/common/sections/layout/Nav/MobileMenu';
import {makeStyles} from '@material-ui/styles';
import clsx from 'clsx';

export interface NewNavInterface {
  showTransparentBg?: boolean;
  subNav?: Array<{
    href: string;
    label: string;
    sub?: {
      title: string;
      links: Array<{
        href: string;
        label: string;
      }>;
    };
  }>;
  rebatesText?: string;
  rebatesLink?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  desktopContainer: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
  mobileContainer: {
    display: 'block',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  transparentBar: {
    background: 'transparent',
    boxShadow: 'none',
  },
}));

const Nav: FC<NewNavInterface> = (props) => {
  const {showTransparentBg = false, subNav, rebatesLink, rebatesText} = props;
  const classes = useStyles();
  const [isTop, setIsTop] = useState<boolean>(showTransparentBg);

  if (showTransparentBg) {
    useEffect(() => {
      const handleScroll = () => {
        if (window.scrollY > 100) {
          setIsTop(false);
        } else {
          setIsTop(true);
        }
      };
      window.addEventListener('scroll', handleScroll);

      return () => {
        window.removeEventListener('scroll', handleScroll);
        setIsTop(false);
      };
    }, []);
  }

  return (
    <AppBar className={clsx(showTransparentBg && classes.transparentBar)}>
      <div className={classes.desktopContainer}>
        <DesktopMenu
          showTransparentBg={isTop} subNav={subNav}
          rebatesText={rebatesText}
          rebatesLink={rebatesLink}
        />
      </div>
      <div className={classes.mobileContainer}>
        <MobileMenu
          showTransparentBg={isTop} subNav={subNav}
          rebatesText={rebatesText}
          rebatesLink={rebatesLink}
        />
      </div>
    </AppBar>
  );
};

export default Nav;
