import React, { FC } from 'react';
import { withStyles } from '@material-ui/styles';
import {Box, Button, Container, Grid, Theme} from '@material-ui/core';
import { NavLinkDropdownDesktop, PageLink } from 'components/common/atoms';
import RebatesLink from "../../../../../components/common/atoms/RebatesLink";

export interface SubNavProps {
  links: Array<{
    href: string;
    label: string;
    sub?: {
      title: string;
      links: Array<{
        href: string;
        label: string;
      }>;
    };
  }>;
  rebatesLink?: string;
  rebatesText?: string;
}

const SubWrapper = withStyles(() => ({
  root: {
    background: '#E8EBEC',
  },
}))(Box);

const SubContainer = withStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 58,
    background: '#E8EBEC',
    '& > span': {
      fontSize: 16,
      textAlign: 'right',
      [theme.breakpoints.up('lg')]: {
        fontWeight: 600,
        fontSize: 18,
      },
      color: '#333',
      willChange: 'color',
      transition: 'color 0.2s ease',
      '&:hover': {
        color: theme.palette.primary.main,
      },
      '& svg': {
        '& path': {
          fill: '#252c32',
        },
      },
    },
  },
}))(Container);

const SubNavDesktop: FC<SubNavProps> = (props) => {
  const { links, rebatesLink, rebatesText} = props;

  return (
    <SubWrapper>
      <SubContainer>
        <RebatesLink href={rebatesLink} text={rebatesText}/>
        {links.map((link) => (
          <span key={link.href}>
            {link.sub ? (
              <NavLinkDropdownDesktop
                links={link.sub.links}
                label={link.label}
                title={link.sub.title}
              />
            ) : (
              <PageLink href={link.href} aria-label={link.label}>
                {link.label}
              </PageLink>
            )}
          </span>
        ))}
      </SubContainer>
    </SubWrapper>
  );
};

export default SubNavDesktop;
