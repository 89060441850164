import React, { FC } from 'react';
import { withStyles } from '@material-ui/styles';
import {Box, Theme} from '@material-ui/core';
import { NavLinkDropdownMobile, PageLink } from 'components/common/atoms';
import { SubNavProps } from 'containers/common/sections/layout/Nav/SubNavDesktop';
import RebatesLink from "../../../../../components/common/atoms/RebatesLink";

const SubContainer = withStyles((theme: Theme) => ({
  root: {
    background: '#E8EBEC',
    paddingBottom: theme.spacing(5),
    [theme.breakpoints.up('md')]: {
      paddingBottom: theme.spacing(0),
    },
    '& > span > a': {
      display: 'block',
      width: '100%',
      color: '#333',
      fontSize: 16,
      fontWeight: 600,
      background: '#E8EBEC',
      padding: '12px 16px',
    },
  },
}))(Box);

const SubNavMobile: FC<SubNavProps> = (props) => {
  const { links, rebatesLink, rebatesText } = props;

  return (
    <SubContainer>
      {links.map((link) => (
        <span key={link.href}>
          {link.sub ? (
            <NavLinkDropdownMobile links={link.sub.links} label={link.label} color={'#333'} />
          ) : (
            <PageLink href={link.href} aria-label={link.label}>
              {link.label}
            </PageLink>
          )}
        </span>
      ))}
      <RebatesLink href={rebatesLink} text={rebatesText}/>
    </SubContainer>
  );
};

export default SubNavMobile;
