import { useRouter } from 'next/router';
import retailStores from 'stores/retail';
import { AnnualUsageUnit } from 'services/orders/types';
import { RETAIL_ENERGY_RATES_ROUTE } from 'config/retail/routes';

const useRefreshRetailPlans = () => {
  const router = useRouter();

  const refreshRoute = RETAIL_ENERGY_RATES_ROUTE;

  const { plansStore, plansFilterStore } = retailStores;
  const { sorting, pagination, filter, searchBestPlans, searchPlans } = plansStore;
  const { form, getAnnualUsage, getUnit } = plansFilterStore;

  return (updateBestPlans = true) => {
    const beforeY = window.pageYOffset;
    if (updateBestPlans) {
      searchBestPlans().then();
    }
    searchPlans().then((noPlans) => {
      if (!noPlans) {
        router
          .push(
            refreshRoute({
              ...form,
              ...sorting,
              ...pagination,
              ...filter,
              annualUsage: getAnnualUsage(),
              unit: getUnit().toUpperCase() as AnnualUsageUnit,
            }),
            undefined,
            {
              shallow: true,
              scroll: false,
            }
          )
          .then(() => window.scrollTo(0, beforeY));
      }
    });
  };
};

export default useRefreshRetailPlans;
