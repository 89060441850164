import React, { FC, ReactNode } from 'react';
import { Box, CssBaseline, Theme, ThemeProvider } from '@material-ui/core';
import { Nav } from 'containers/common/sections/layout';
import Footer from 'evcharging/containers/layout/Footer';
import { NewNavInterface } from 'containers/common/sections/layout/Nav/Nav';
import energy5Theme from 'config/themes/energy5Theme';
import EvcAdapter from 'components/adapters/EvcAdapter';

export interface MainLayoutInterface extends NewNavInterface {
  children: ReactNode;
  hideFooterOnly?: boolean;
  theme?: Partial<Theme> | ((outerTheme: Theme) => Theme);
  mt?: number;
  hideNav?: boolean;
}

const MainLayout: FC<MainLayoutInterface> = (props) => {
  const {
    children,
    hideFooterOnly = false,
    hideNav = false,
    theme = energy5Theme,
    showTransparentBg = false,
    mt = 0,
  } = props;
  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {!hideNav && <Nav showTransparentBg={showTransparentBg} />}
        <Box mt={!showTransparentBg ? 13.6 + mt : 0}>{children}</Box>
        {!hideFooterOnly && (
          <EvcAdapter>
            <Footer />
          </EvcAdapter>
        )}
      </ThemeProvider>
    </>
  );
};

export default MainLayout;
