import React, { FC } from 'react';
import { Container, Theme } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/styles';
import { NewNavInterface } from 'containers/common/sections/layout/Nav/Nav';
import { NavLinkDropdownDesktop } from 'components/common/atoms';
import clsx from 'clsx';
import { DarkLogo } from 'assets/svgs/nav';
import SubNavDesktop from 'containers/common/sections/layout/Nav/SubNavDesktop';
import useI18n from "evcharging/hooks/useI18n";
import PageLink from "components/common/atoms/PageLink";
import NavLangs from "evcharging/components/navigation/NavLangs";
import EvcAdapter from "components/adapters/EvcAdapter";
import { dropdownOptions } from 'evcharging/providers/ServicesProvider';

const StyledContainer = withStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    '& > div:first-child': {
      marginRight: 'auto',
    },
    '& > div:last-child': {
      fontSize: '14px',
      fontWeight: 600,
      color: '#93979b',
    },
    '& > span': {
      paddingRight: theme.spacing(6),
      [theme.breakpoints.up('md')]: {
        fontSize: 18,
      },
      color: theme.palette.common.white,
      willChange: 'color',
      transition: 'color 0.2s ease',
      '&:hover': {
        color: theme.palette.primary.main,
      },
    },
  },
}))(Container);

const useStyles = makeStyles((theme: Theme) => ({
  topNav: {
    height: 98,
    backgroundColor: '#333',
    boxShadow: '0px 5px 20px rgba(0, 0, 0, 0.05)',
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.text.primary,
    transition: 'background-color .3s',
  },
  newTopNavTransparent: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
  contactLink: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 150,
    padding: '10px 0',
    background: '#F9C106',
    borderRadius: 6,
    color: '#333',
    fontSize: 18,
    fontWeight: 600,
  },
  catalogLink: {
    padding: '10px 25px',
    border: '1px solid #FFFFFF',
    borderRadius: 6,
  },
}));

const DesktopMenu: FC<NewNavInterface> = ({ showTransparentBg = false, subNav, rebatesText, rebatesLink }) => {
  const { t } = useI18n();
  const classes = useStyles();

  return (
    <>
      <div className={clsx(classes.topNav, showTransparentBg && classes.newTopNavTransparent)}>
        <StyledContainer fixed>
          <div>
            <PageLink href="/" aria-label={'Site Logo'}>
              <DarkLogo />
            </PageLink>
          </div>
          <span>
            <PageLink href="/" aria-label={t('nav.home')}>
              {t('nav.home')}
            </PageLink>
          </span>
          <span>
            <PageLink href="/about-us" aria-label={t('nav.about')}>
              {t('nav.about')}
            </PageLink>
          </span>
          <span>
            <NavLinkDropdownDesktop
              links={dropdownOptions}
              label={t('nav.services')}
              title={''}
            />
          </span>
          {/*<span>
            <PageLink href="/blog" aria-label={t('nav.blog')}>
              {t('nav.blog')}
            </PageLink>
          </span>*/}
          {/*<span>
            <PageLink href="/our-providers" aria-label={t('nav.providers')}>
              {t('nav.providers')}
            </PageLink>
          </span>*/}
          <span>
            <PageLink href="/faq" aria-label={t('nav.faq')}>
              {t('nav.faq')}
            </PageLink>
          </span>
          <PageLink href={'/contact-us'} aria-label={t('nav.contact')} className={classes.contactLink}>
            {t('nav.contact')}
          </PageLink>
          <EvcAdapter>
            <NavLangs />
          </EvcAdapter>
        </StyledContainer>
      </div>
      {subNav && <SubNavDesktop links={subNav} rebatesLink={rebatesLink} rebatesText={rebatesText} />}
    </>
  );
};

export default DesktopMenu;
